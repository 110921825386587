<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="$t('permission.title.properties', [entity !== null ? $t(`permission.${entity.toLowerCase()}`) : null])" 
      footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      :ok-title="$t('button.select')"
    >
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <b-button-toolbar class="py-1 action-toolbar">
        <b-btn class="action-button" @click="selectAll">{{ $t('button.selectall') }}</b-btn>
        <b-btn class="action-button" @click="selectNone">{{ $t('button.selectnone') }}</b-btn>
      </b-button-toolbar>
        
      <b-input-group class="mb-2">
        <b-form-input
          size="md"
          v-model="searchFilter"
          @input="applyFilter"
          :placeholder="$t('dataview.search')">
        </b-form-input>
        <b-input-group-append>
          <b-input-group-text>
            <font-awesome-icon :icon="['far', 'magnifying-glass']"/>
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      
      <ag-grid-vue style="width: 100%;height: 400px" class="ag-theme-balham permission-grid-height checklist-grid" id="contact-grid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          animateRows
          :autoGroupColumnDef="autoGroupColumnDef"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :getRowId="params => params.data.label"
          groupDefaultExpanded
          :rowData="rowData"
          :rowSelection="'multiple'"
          rowMultiSelectWithClick
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressContextMenu=true
          suppressMultiSort
          :suppressRowClickSelection="readOnly"
          >
      </ag-grid-vue>
               
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button v-if="!readOnly" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>

  </div>
</template>

<script>
import { debounce } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { strRandom, isBlacklistedPermission } from '@/helpers';
import PermissionLabelCellRenderer from '@/components/Aggrid/CellRenderer/PermissionLabel';

export default {
  name: 'PropertiesPermissionModal',
  components: {
    'ag-grid-vue': AgGridVue,
    'permissionLabelCellRenderer': PermissionLabelCellRenderer, // eslint-disable-line vue/no-unused-components
  },
  props: {
    show:         { type: Boolean, required: true },
    isEdit:       { type: Boolean, default: false },
    isAdd:        { type: Boolean, default: false },
    entity:       { type: String, default: null },
    schema:       { type: Object, default: () => { return {} } },
    macros:       { type: Object, default: () => { return {} } },
    denyRules:    { type: Array, default: () => { return [] } },
    readOnly:     { type: Boolean, default: false }
  },
  data() {
    return {
      id: `PERMISSION_MODAL_${strRandom(5)}`,
      gridOptions: null,
      gridApi: null,
      autoGroupColumnDef: null,
      columnDefs: [],
      columnApi: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      
      permissionsTemplate: [],
      state: {
        dataUpdating: false
      },
      alertError: false,
      alertMsg: null,
      modalShow: false,
      searchFilter: ''
    }
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      isExternalFilterPresent: function() {
        return self.searchFilter.length > 0;
      },
      doesExternalFilterPass: function doesExternalFilterPass(node) {
        if (node.data) {
          return node.data.label.toLowerCase().indexOf(self.searchFilter.toLowerCase()) !== -1;
        }
        return false;
      },
      onSelectionChanged: debounce((event) => {
        if(!this.state.dataUpdating) {
          this.$emit('selected', event.api.getSelectedNodes().map(i => {
            if (typeof i.data !== 'undefined') { 
              return { uuId: i.data.uuId, name: i.data.name, path: i.data.path }; 
            }
            
          }));
        }
      }, 300),
      onColumnVisible: function(event) {
        event.api.sizeColumnsToFit();
      }
    };
    this.columnDefs = [{
      headerName: this.$t('permission.field_permissions'),
      minWidth: 200,
      checkboxSelection: true,
      cellRenderer: 'permissionLabelCellRenderer',
      cellRendererParams: {
        readOnly: this.readOnly
      },
      sortingOrder: ['asc'],
      sort: 'asc',
      hide: false   
    }];
    const cellClassRules = {
      "child-entity": params => params.data && params.data.label === params.data.label.toUpperCase()
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      cellClassRules: cellClassRules,
      menuTabs: []
    };
    
    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        
        this.alertMsg = null;
        this.modalShow = newValue;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectOrientationChange);
    this.delimiter = null;
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('access_policy.grid.no_data') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    showError() {
      return this.alertMsg != null;
    },
    getFields() {
      var entity = this.entity;
      var ret = [];
      if (entity !== null && this.schema !== null) {

        var schemaObj = this.schema[entity];
        if (typeof schemaObj === 'string') {
          entity = schemaObj;
          schemaObj = this.schema[entity];
        }         
          
        // macros
        const keys = [];
        if (!this.isEdit) {
          for (var key in this.macros) {
            if ((this.macros[key].arguments[0].type.split(',').includes(entity) ||
                (this.macros[key].arguments[0].type === "PATH.MODEL.property" &&
                 this.macros[key].arguments.length === 1 && key !== '=stamp(A)' && key !== '=fullPath(A)'))) {
              keys.push(key);
            }
          }
        }
                
        const properties = schemaObj.properties;
        if (properties !== null && typeof properties !== 'undefined') {
          const filteredProperties = properties.filter(entry => this.shouldAdd(entry, entity));
          const fields = filteredProperties.map(p => { return p.field });
          
          ret = fields.map(f => { return { label: f } });  
        }
        
        // do not show entities on the ADD permissions list
        if (!this.isAdd) {
          const connections = schemaObj.connections;
          if (connections !== null && typeof connections !== 'undefined') {
            const fields = connections.filter(c => !isBlacklistedPermission(entity, c, this.isAdd)).map(p => { return { label: p } });
            
            // workaround for connections in schema not listing TASK
            if (entity === 'TASK') {
              fields.push({ label: 'TASK' });
              fields.sort();
            }
            
            ret = [...fields, ...ret, ...keys.map(k => { return { label: k } })];  
          }
        }
      }
      
      return ret;
    }
  },
  methods: {
    shouldAdd(entry, entity) {
      return (entry.type !== 'UUID' || entry.field === 'avatarRef' || entry.field === 'bannerRef') && 
            entry.field !== 'propertyType' &&
            !(entry.field.endsWith('List') && entry.type.startsWith('List')) &&
            !entry.field.endsWith('Link') &&
            isBlacklistedPermission(entity, entry.field, this.isEdit || this.isAdd) === false &&
            ((!this.isEdit && !this.isAdd) || !entry.readOnly);
    },
    label(params) {
      if (params.data) {
        return params.data.label;
      }
      return "N/A";
    },
    onGridReady(/* params */) {
      const self = this;
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.rowData = this.getFields;
      setTimeout(() => {
        this.gridApi.forEachNode(node =>  {
          if(node) {
            if (self.denyRules.findIndex(r => r === node.data.label) !== -1) {
              node.setSelected(false);
            }
            else {
              node.setSelected(true);
            }
          }
        });
        this.gridApi.sizeColumnsToFit();  
      }, 100);
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.gridApi) {
          this.gridApi.sizeColumnsToFit();  
        }
      }, 100);
    },
    selectAll() {
      const self = this;
      this.gridApi.forEachNode(function(node) {
        if (typeof node.data !== 'undefined' &&
            (self.searchFilter === '' || node.data.label.toLowerCase().indexOf(self.searchFilter.toLowerCase()) !== -1)) {
          node.setSelected(true);
        }
      });
    },
    selectNone() {
      this.gridApi.forEachNode(function(node) {
        node.setSelected(false);
      });
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      const unselected = [];
      this.gridApi.forEachNode(node =>  {
        if(node && !node.selected) {
          unselected.push(node.data.label);
        }
      });
      this.$emit('update:show', false);
      this.$emit('success', unselected);
    },  
    dismissAlert() {
      this.alertMsg = null;
      this.alertError = false;
    },
    applyFilter(pattern) {
      
      this.searchFilter = pattern;
      
      this.gridOptions.api.onFilterChanged();
    }
  }
}
</script>

<style lang="scss">
.field-modal {
  min-height: 350px;
}

.editing-field {
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 0 0.2rem var(--form-control-focus-shadow);
  box-shadow: 0 0 0 0.2rem var(--form-control-focus-shadow);
}

.action-button {
  margin: 4px;
}

.field-select-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--form-control-border);
  border-radius: 3px;
  padding: 3px;
}

.field-dot {
  display: inline-block;
  font-weight: bold;
  vertical-align: text-bottom;
  margin: 2px;
}

.field-label {
  padding: 7px;
  margin-right: 5px;
}

.column-list {
  display: inline-block;
  margin: 1px;
  border: 1px solid var(--border-medium);
  border-radius: 3px;
}

.child-entity {
  font-style: italic;
}

.content-tab {
  max-height: 300px;
  overflow-y: auto;
}

.root-edit {
  background-color: var(--form-control-disabled-bg);
}

.child-entity {
  font-style: italic;
}

.ag-row-selected::before,
.ag-row-selected.ag-row-hover::before {
  background-color: transparent;
}

</style>
